<template>
  <el-config-provider :locale="zhCn">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
let router = useRouter()
let route = useRoute()

// 判断浏览器窗口区分移动端PC端
winInner(router)
function winInner(router) {
  let px = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  if (px > 1180) {
    // PC
    router.push('/login')
  } else {
    // 移动
    let path = route.path
    let pathname = window.location.pathname
    if(pathname == '/register' || pathname == '/r' || pathname == '/reg'  ){
      router.push('/register')
      return
    }
    if (path == '/' || path == '/login') {
      router.push('/ruKou')
      return
    }
    router.push(path)
  }
}
window.onresize = function () {
    winInner(router)
}
</script>
<style>
@import './assets/css/main.css';
@import './assets/css/color-dark.css';
</style>