import { createRouter, createWebHistory } from 'vue-router'
import items from '@/router/menuTree.js'
import { useStore } from 'vuex'


const routes = [
  {
      path: '/',
      // redirect: '/ruKou',
      // redirect: '/login',
  },
  // 以下手机
  {
    path: '/ruKou',
    name: 'ruKou',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/ruKou/ruKou.vue')
  },
  
  {
    path: '/baoming',
    name: 'baoming',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/baoming.vue'),
    meta: {
      needLogin:false,
      title: '没有权限',
    },
  },
  // register
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/register.vue')
  },
  {
    path: '/ediltUser',
    name: 'ediltUser',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/extend/ediltUser.vue')
  },
  {
    path: '/xinyuzhixing',
    name: 'xinyuzhixing',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/xinyuzhixing.vue')
  },
  {
    path: '/fandating',
    name: 'fandating',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/fandating.vue')
  },
  {
    path: '/landlords',
    name: 'landlords',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/landlords.vue')
  },
  {
    path: '/yanli',
    name: 'yanli',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/yanli.vue')
  },
  {
    path: '/mofang',
    name: 'mofang',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/mofang.vue')
  },
  {
    path: '/kantucaici',
    name: 'kantucaici',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/kantucaici.vue')
  },
  {
    path: '/xiaoxiaole',
    name: 'xiaoxiaole',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/xiaoxiaole.vue')
  },
  {
    path: '/feijidazhan',
    name: 'feijidazhan',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/feijidazhan.vue')
  },

  {
    path: '/consoleuser',
    name: 'consoleuser',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/consoleuser.vue')
  },
  {
    path: '/caichengyu',
    name: 'caichengyu',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/sys/caichengyu.vue')
  },
  {
    path: '/marriageNotice',
    name: 'marriageNotice',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/marriageNotice.vue')
  },
  {
    path: '/marriageInformation',
    name: 'marriageInformation',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/marriageInformation.vue')
  },
  {
    path: '/datingHistory',
    name: 'datingHistory',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/datingHistory.vue')
  },
  {
    path: '/matchmakingService',
    name: 'matchmakingService',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/home/index.vue')
  },{
    path: '/blindSeekingList',
    name: 'blindSeekingList',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/blindSeekingList.vue')
  },
  {
    path: '/objectAffection',
    name: 'objectAffection',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/objectAffection.vue')
  },{
    path: '/specialCommunity',
    name: 'specialCommunity',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/specialCommunity.vue')
  },
  {
    path: '/matchmakingArrangement',
    name: 'matchmakingArrangement',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/matchmakingArrangement.vue')
  },
  {
    path: '/messageMarriageRequisition',
    name: 'messageMarriageRequisition',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/messageMarriageRequisition.vue')
  },
  {
    path: '/marriageExaminationApproval',
    name: 'marriageExaminationApproval',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/marriageExaminationApproval.vue')
  },
  {
    path: '/processInstruction',
    name: 'processInstruction',
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/blindSeeking/processInstruction.vue')
  },
  {
    path: '/friendApproval',
    name: 'friendApproval',
    meta: {
      title: '好友审批',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/friendApproval.vue')
  },
  {
    path: '/marriagevip',
    name: 'marriagevip',
    meta: {
      title: '添加好友',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/marriagevip.vue')
  },
  {
    path: '/marriageDate',
    name: 'marriageDate',
    meta: {
      title: '红娘数据',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/marriageDate.vue')
  },

  {
    path: '/organizeActivities',
    name: 'organizeActivities',
    meta: {
      title: '组织活动',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/extend/organizeActivities.vue')
  },
  {
    path: '/updateNotice',
    name: 'updateNotice',
    meta: {
      title: '更新公告',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/extend/updateNotice.vue')
  },
  {
    path: '/entranceExamination',
    name: 'entranceExamination',
    meta: {
      title: '入门考试',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/extend/entranceExamination.vue')
  },
  {
    path: '/fastData',
    name: 'fastData',
    meta: {
      title: '快手资料',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/extend/marriageDataManagement.vue')
  },
  {
    path: '/friendExtrapolation',
    name: 'friendExtrapolation',
    meta: {
      title: '朋友内推',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/extend/friendExtrapolation.vue')
  },
  {
    path: '/idcard',
    name: 'idcard',
    meta: {
      title: '红娘笔记',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/idcard.vue')
  },
  {
    path: '/mycard',
    name: 'mycard',
    meta: {
      title: '我的笔记',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/note/idcard.vue')
  },
  {
    path: '/idcardDetil',
    name: 'idcardDetil',
    meta: {
      title: '笔记详情',
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/IdcardDetil.vue')
  },
  {
    path: '/membershiprebate',
    name: 'membershiprebate',
    meta: {
      title: '会员返利',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/matchmakertest/membershiprebate.vue')
  },
  {
    path: '/addFriends',
    name: 'addFriends',
    meta: {
      title: '好友审批',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/addFriends.vue')
  },
  {
    path: '/matchmakertest',
    name: 'matchmakertest',
    meta: {
      title: '红娘考核',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/matchmakertest/matchmakertest.vue')
  },
  {
    path: '/rejecterInform',
    name: 'rejecterInform',
    meta: {
      title: '拒绝通知',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/rejecterInform.vue')
  },
  {
    path: '/marriageDataManagement',
    name: 'marriageDataManagement',
    meta: {
      title: '资料管理',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/marriageDataManagement.vue')
  },
  {
    path: '/matchmakerAfterSalesService',
    name: 'matchmakerAfterSalesService',
    meta: {
      title: '红娘售后',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/matchmakerAfterSalesService.vue')
  },
  {
    path: '/toolAssistant',
    name: 'toolAssistant',
    meta: {
      title: '工具助手',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/matchmakingService/huechys/toolAssistant.vue')
  },
  {
    path: '/shopping',
    name: 'shopping',
    meta: {
      title: '商城',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/shopping/index.vue')
  },
  {
    path: '/friends',
    name: 'friends',
    meta: {
      title: '社区',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/friends/index.vue')
  },
  {
    path: '/my',
    name: 'my',
    meta: {
      title: '我的',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/index.vue')
  },
  {
    path: '/setList',
    name: 'setList',
    meta: {
      title: '设置列表',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/setList.vue')
  },
  {
    path: '/publish',
    name: 'publish',
    meta: {
      title: '社区发布',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/setList/publish.vue')
  },
  {
    path: '/communityApproval',
    name: 'communityApproval',
    meta: {
      title: '审批日志',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/setList/communityApproval.vue')
  },
  {
    path: '/communityManagement',
    name: 'communityManagement',
    meta: {
      title: '社区管理',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/setList/communityManagement.vue')
  },
  {
    path: '/buyLocation',
    name: 'buyLocation',
    meta: {
      title: '我的地址',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/setList/buyLocation.vue')
  },
  {
    path: '/myUserInfo',
    name: 'myUserInfo',
    meta: {
      title: '个人信息',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/setList/myUserInfo.vue')
  },
  {
    path: '/aboutFail',
    name: 'aboutFail',
    meta: {
      title: '关于我们',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/setList/aboutFail.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '商品详情',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/shopping/product.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      title: '购物车',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/shopping/cart.vue')
  },
  {
    path: '/profilePictureId',
    name: 'profilePictureId',
    meta: {
      title: '我的头像',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/userInfo/profilePictureId.vue')
  },
  {
    path: '/userInfoBase',
    name: 'userInfoBase',
    meta: {
      title: '基本资料',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/userInfo/userInfoBase.vue')
  },
  {
    path: '/password',
    name: 'password',
    meta: {
      title: '密码修改',
    },
    component: () => import(/* webpackChunkName: "about" */ '../mobile/my/userInfo/password.vue')
  },
  {
    path: '/goldEgg',
    name: 'goldEgg',
    meta: {
      title: '砸金蛋',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/drawlotteryraffle/goldEgg.vue'),
  },
  {
    path: '/guaguale',
    name: 'guaguale',
    meta: {
      title: '刮刮乐',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/drawlotteryraffle/guaguale.vue'),
  },
  {
    path: '/nineGrid',
    name: 'nineGrid',
    meta: {
      title: '九宫格',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/drawlotteryraffle/nineGrid.vue'),
  },
  {
    path: '/zhuanpan',
    name: 'zhuanpan',
    meta: {
      title: '大转盘',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/drawlotteryraffle/zhuanpan.vue'),
  },
  {
    path: '/memberIntroduction',
    name: 'memberIntroduction',
    meta: {
      title: '会员介绍',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/blindSeeking/memberIntroduction.vue'),
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    meta: {
      title: '评价',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/drawlotteryraffle/evaluate.vue'),
  },
  {
    path: '/messageBoard',
    name: 'messageBoard',
    meta: {
      title: '留言',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/drawlotteryraffle/messageBoard.vue'),
  },
  {
    path: '/organization',
    name: 'organization',
    meta: {
      title: '组织',
    },
    component: () => import(/* webpackChunkName: "login" */ '../mobile/matchmakingService/drawlotteryraffle/organization.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      title: '店铺',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/my/shop/dianpu/index.vue'),
  },
  {
    path: '/shoptypemerchandise',
    name: 'shoptypemerchandise',
    meta: {
      title: '店铺',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/my/shop/shoptypemerchandise/index.vue'),
  },
  {
    path: '/shopmerchandize',
    name: 'shopmerchandize',
    meta: {
      title: '店铺',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/my/shop/shopmerchandize/index.vue'),
  },
  {
    path: '/commodityManagement',
    name: 'commodityManagement',
    meta: {
      title: '店铺',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/my/shop/commodityManagement/index.vue'),
  },
  {
    path: '/shopList',
    name: 'shopList',
    meta: {
      title: '商品列表',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/shopping/shop/shopList.vue'),
  },
  {
    path: '/operationCustomerService',
    name: 'operationCustomerService',
    meta: {
      title: '客服管理',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/matchmakingService/matchmakertest/yunying.vue'),
  },
  {
    path: '/ziliaoluru',
    name: 'ziliaoluru',
    meta: {
      title: '资料录入',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/matchmakingService/matchmakertest/ziliaoluru.vue'),
  },
  // 
  {
    path: '/pushEachOther',
    name: 'pushEachOther',
    meta: {
      title: '客服管理',
    },
    component: () => import(/* webpackChunkName: "403" */ '../mobile/matchmakingService/huechys/pushEachOther.vue'),
  },
  // 
  // 以下PC
  {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard/home.vue'),
      children: items
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/entrance/index.vue'),
  },
  
  {
    path: '/noAccess',
    name: 'noAccess',
    meta: {
      title: '没有权限',
    },
    component: () => import(/* webpackChunkName: "403" */ '../views/message/noAccess.vue'),
  },
  {
    path: '/pageDoesNotExist',
    name: 'pageDoesNotExist',
    meta: {
      title: '找不到页面',
    },
    component: () => import(/* webpackChunkName: "403" */ '../views/message/pageDoesNotExist.vue'),
  },
  
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to,from,next)=>{
  let needLogin = to.matched.some(match=>{
    return match.meta.needLogin
  })
  // jwt
  console.log(needLogin,'needLogin')
  if(needLogin){// 如果需要登录
    const store = useStore()
    // 发送请求更新 user
    let isLogin = await store.dispatch('toValidate')
    let userInfo = store.state.userInfo
    let jurisdiction = userInfo.jurisdiction?JSON.parse(userInfo.jurisdiction) :{}
    let menuJurisdiction = jurisdiction.menuJurisdiction?jurisdiction.menuJurisdiction:['/dashboard']
    if((isLogin && menuJurisdiction.includes(window.location.pathname)) || store.state.userInfo.identityCardType == '9e01fbe3-0511-11ee-b1f3-5405dbeb0924'){
      //并且登录了并且是 有权限的菜单
      next()
    }else{// 却没登录
      console.log('无权访问')
      next('/login')
    }
  }else{// 不需要登录
      next()
  }
})
export default router
